<template>
    <div class="over-page flex left align-left">
        <div class="left-menu" :style="leftWidth">
            <div :class="collapsed?'flex logo-box':'flex column align-left logo-box'" :style="collapsed?'padding:0':''">
                <img v-if="!collapsed" src="/logo/logo.png" style="height: 16px;" alt="">
                <span v-if="!collapsed" >{{$t('header.name')}}</span>
                <img v-if="collapsed" src="/logo/logoD.png"  alt="">
            </div>
            <a-menu
                    mode="inline"
                    theme="dark"
                    @select="({key})=>router.push({name:key})"
                    v-model:selectedKeys="selectedKeys"
                    :openKeys="openKeys"
                    @openChange="onOpenChange"
                    :inline-collapsed="collapsed">
                <a-sub-menu key="device">
                    <template #icon>
                        <DatabaseOutlined />
                    </template>
                    <template #title>{{$t('menu.deviceManger')}}</template>
                    <a-menu-item key="deviceList">{{$t('menu.deviceList')}}</a-menu-item>
                    <a-menu-item key="redirect">{{$t('menu.redirect')}}</a-menu-item>
                </a-sub-menu>
                <a-sub-menu key="user">
                    <template #icon>
                        <UserOutlined />
                    </template>
                    <template #title>{{$t('menu.userManger')}}</template>
                    <a-menu-item v-if="ifAdmin" key="userList">{{$t('menu.userList')}}</a-menu-item>
                    <a-menu-item key="userPersonSet">{{$t('menu.personSet')}}</a-menu-item>
                </a-sub-menu>
                <a-menu-item key="systemLog">
                    <template #icon>
                        <ReadOutlined />
                    </template>
                    <span>{{$t('menu.systemLog')}}</span>
                </a-menu-item>
            </a-menu>
        </div>
        <div class="right-view" :style="rightWidth">
            <div class="flex between header">
                <div class="flex left" style="width: calc(100% - 180px - 22px)">
                    <div class="flex collapsed" @click="collapsed=!collapsed">
                        <MenuUnfoldOutlined v-if="collapsed" />
                        <MenuFoldOutlined v-else />
                    </div>
                    <app-tabs class="grow-1"/>
                </div>
                <div class="flex right" style="width: 180px;margin-right: 22px;">
                    <app-locale class="grow-1"/>
                    <div class="flex logout grow-1" @click="logout">
                        <LogoutOutlined />
                        <span >{{$t('header.logout')}}</span>
                    </div>
                </div>
            </div>
            <div class="layout">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import {MenuUnfoldOutlined,MenuFoldOutlined,LogoutOutlined,UserOutlined,DatabaseOutlined,ReadOutlined} from '@ant-design/icons-vue'
    import AppLocale from "../components/appLocale";
    import AppTabs from "../components/appTabs";
    import {ref,computed,onMounted} from 'vue';
    import {useRoute,useRouter} from 'vue-router';
    import constName from '../utils/constName'
    import {localStore} from '../utils/main'
    import {useStore} from "vuex";
    let routeName=constName.route
    export default {
        name:'LoggedIn',
        components:{
            AppTabs,
            AppLocale,
            MenuUnfoldOutlined,
            MenuFoldOutlined,
            LogoutOutlined,
            DatabaseOutlined,
            UserOutlined,
            ReadOutlined,
        },
        setup(){
            let store = useStore()
            let route=useRoute()
            let router=useRouter()
            let selectedKeys=ref([route.name])
            let collapsed=ref(false)
            const openFunc=()=>{
                if(!route||!route.name){
                    return []
                }
                if(route.name.indexOf('device')>-1){
                    return ['device']
                }else if(route.name.indexOf('redirect')>-1){
                    return ['device']
                }else if(route.name.indexOf('user')>-1){
                    return ['user']
                }else{
                    return []
                }
            }
            let openKeys=ref(openFunc())
            let leftWidth=computed(()=>{
                if(!collapsed.value){
                    return 'width:260px;'
                }
                return 'width:80px;'
            })
            let rightWidth=computed(()=>{
                if(!collapsed.value){
                    return 'width: calc(100% - 260px);'
                }
                return 'width: calc(100% - 80px);'
            })

            if(route.meta.root){
                selectedKeys.value=[route.meta.root]
            }
            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)

            onMounted(async()=>{
                await store.dispatch('user/init')
            })

            return{
                router,
                collapsed,
                ifAdmin,
                openKeys,
                selectedKeys,
                logout(){
                    //清除用户信息
                    localStore.clear()
                    store.dispatch('appTabs/clear')
                    store.dispatch('device/clear')
                    store.dispatch('user/clear')
                    store.dispatch('log/clear')
                    router.push({name:routeName.login})
                },
                leftWidth,
                rightWidth,
                onOpenChange(keys){
                    const latestOpenKey = keys.find(key => openKeys.value.indexOf(key) === -1);

                    if (['device','user'].indexOf(latestOpenKey) === -1) {
                        openKeys.value = keys;
                    } else {
                        openKeys.value = latestOpenKey ? [latestOpenKey] : [];
                    }
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .left-menu,.right-view{
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .left-menu{
        background: #001529;
    }
    .right-view{
        background: #F5F7FA;
    }
    .logo-box,.header{
        height: 64px
    }
    .logo-box{
        color: #ffffff;
        background: #073B70;
        font-size: 14px;
        padding: 0 36px;
        span{
            padding-top: 2px;
        }
        img{
        }
    }
    .collapsed{
        height: 100%;
        width: 64px;
        cursor: pointer;
        font-size: 20px;
        &,::v-deep(span){
            color: #7f7f7f !important;
        }
    }
    .header{
        background: #FAFBFC;
        box-shadow: 0 0 8px 1px #cfcfcf;
        font-size: 14px;
        z-index: 999;
        position: relative;
        >div{
            height: 100%;
        }

        &,::v-deep(span){
            color:rgba(0,0,0,0.85);
        }

        .logout{
            height: 100%;
            width: 64px;
            cursor: pointer;
            &,span{
                color: #1890FC;
            }
            .anticon{
                margin-right: 8px;
            }
        }
    }
    .layout{
        padding: 24px 20px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: calc(100vh - 64px);
        min-width: 960px;
    }
</style>