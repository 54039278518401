<template>
    <div class="app-tabs flex left">
        <div v-for="(item,key) in tabs" :key="key"
             :class="item.active?'flex around tab-item active':'flex around tab-item'"
             @click.stop="(e)=>tabItemHandle(e,item,key)">
            <div class="name" :title="$t(item.name)">{{$t(item.name)}}</div>
            <div v-if="tabs.length>1" class="close" @click.stop="$store.dispatch('appTabs/reduceTabs',{...item,key})">
                <CloseOutlined />
            </div>
        </div>
    </div>
</template>

<script>
    import {CloseOutlined} from '@ant-design/icons-vue'
    import {useStore} from 'vuex'
    import {computed} from 'vue'
    import {useRouter,useRoute} from 'vue-router'

    export default {
        components: {
            CloseOutlined
        },
        setup() {

            let store = useStore()
            let router = useRouter()
            let route = useRoute()

            let tabs = computed(()=>store.state.appTabs.arr)
            return {
                tabs,
                tabItemHandle(e, item) {

                    if(route.name===item.routeName){
                        return;
                    }
                    store.dispatch('appTabs/changeActive',item)
                    router.push({name: item.routeName,query:item.query,params:item.params})
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-tabs {
        width: initial;
        height: 100%;
        transition: all 0.3s;

        .tab-item {
            transition: all 0.3s;
            flex-wrap: nowrap;
            width: 120px;
            height: 100%;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            border-right: 1px solid rgba(0,0,0,0.04);
            .name{
                width: 80px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
            .close{
                width: 20px;
            }
            &.active{
                background: rgba(0,0,0,0.04);
                border-bottom-color:rgba(0,0,0,0.6);
                width: 160px;
                .name{
                    width: 120px;
                }
            }
        }
    }
</style>